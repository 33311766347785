/* global env */
export default {
    DEBUG: env.VUE_APP_DEBUG === 'true',
    VUE_APP_AG_GRID_LICENSE_KEY: env.VUE_APP_AG_GRID_LICENSE_KEY,
    API: {
        URL: env.VUE_APP_API_URL,
        PREFIX: {
            SEGMENT: 'track/',
        },
        ROUTES: {
            ACTIONS: {
                OVERVIEW: '/actions/overview',
                GET_ALLOWED: '/actions/{accountId}/get-allowed-actions',
            },
            ACCOUNT: {
                PASSWORD: {
                    RECOVER_REQUEST: '/auth/password/recovery-request',
                    RESET: '/auth/password/recovery',
                },
            },
            AGENTS: {
                OVERVIEW: '/agents/overview',
                ADD: '/agents/add',
                GET: '/agents/{agentId}',
                UPDATE: '/agents/update/{agentId}',
                REVOKE: '/agents/revoke/{agentId}',
                REACTIVATE: '/agents/reactivate/{agentId}',
                PASSWORD: {
                    UPDATE: '/agents/password/update/{agentId}',
                },
            },
            APPLICATIONS: {
                OVERVIEW: '/applications/overview',
                GET_ALLOWED: '/applications/{accountId}/get-allowed-applications',
                MANAGEMENT_OVERVIEW: '/applications/managementoverview',
                GET: '/applications/get/{applicationId}',
                ADD: '/applications/add',
                UPDATE: '/applications/update',
                ENABLE_DISABLE: '/applications/enableDisable',
            },
            APPLICATIONS_GROUPS: {
                OVERVIEW: '/applicationsgroups/overview',
                ADD: '/applicationsgroups/add',
                GET: '/applicationsgroups/{groupId}',
                UPDATE: '/applicationsgroups/update',
                REVOKE: '/applicationsgroups/revoke',
            },
            ARTWORKS: {
                APPROVE: '/artworks/approve/{accountId}/{orderId}',
                ARCHIVE: '/artworks/archive/{accountId}/{orderId}',
                UPLOAD: '/artworks/upload/{customerId}/{orderId}/{orderLineId}',
                ATTACH: '/artworks/attach/{accountId}/{orderId}',
                DOWNLOAD_ALL: '/artworks/download-all/{orderLineId}',
                DOWNLOAD: '/artworks/download/{url}',
                GET_TEMPLATES: '/artworks/templates/overview',
                PREVIOUS_ARTWORK: '/artworks/{accountId}/previousartwork',
                ARCHIVE_ALL_ARTWORKS: '/artworks/archive-all/{accountId}/{orderId}',
                GET_DOWNLOAD_URL: '/artworks/getdownloadurl',
                SET_TO_MANUAL_CHECK: '/artworks/{accountId}/settomanualcheck',
                SET_TO_REQUEST_INFO: '/artworks/{accountId}/settorequestinfo',
                GET_ORDERLINE_ARTWORK: '/artworks/{orderLineId}/getorderlineartwork',
            },
            CARRIERS: {
                GET_CURRENCY_CONVERSION_RATES: '/carriers/conversionrates',
            },
            CART: {
                ACCEPT: '/cart/accept/{accountId}/{cartId}',
                ADD_ATTACHMENT: '/cart/attachments/add/{accountId}/{cartId}',
                ADD_ITEM: '/cart/{accountId}/{cartId}/additem',
                ADD_QUOTE_ITEM: '/cart/{accountId}/{cartId}/addquoteitem',
                APPROVE: '/cart/approve/{accountId}/{cartId}',
                BILLING_INFO: '/cart/{accountId}/{cartId}/billinginfo',
                CREATE: '/cart/create',
                GET: '/cart/{accountId}/{cartId}',
                GET_ATTACHMENT_DOWNLOAD_URL: '/cart/attachments/download',
                GET_QUOTE_PDF_DOWNLOAD_URL: '/cart/download',
                ITEM_DELIVERY_INFO: '/cart/{accountId}/{cartId}/{cartItemId}/deliveryinfo',
                ITEM_REMOVE: '/cart/{accountId}/{cartId}/{cartItemId}/remove',
                LIST_FOR_ACCOUNT: '/cart/{accountId}/overview',
                OVERVIEW: '/cart/overview',
                REJECT: '/cart/reject/{accountId}/{cartId}',
                REMOVE_ATTACHMENT: '/cart/attachments/remove/{accountId}/{cartId}/{quoteAttachmentId}',
                SET_QUOTE_ATTACHMENT_PERMISSION: '/cart/attachments/setpermission/{quoteId}/{quoteAttachmentId}',
                UPDATE: '/cart/update/{accountId}/{cartId}',
                UPLOAD_ATTACHMENTS: '/cart/upload/{organisationId}',
            },
            CATALOG: {
                ADD_PRODUCT_TO_CATALOG: '/catalog/addProductToCatalog',
                SET_CATALOG_PRODUCT_APPLICATIONS: '/catalog/applications/set',
                ATTRIBUTES: {
                    OVERVIEW: '/catalog/attributes/overview',
                },
                CONTENT: {
                    CREATE: '/catalog/content/create',
                },
                DELETE_CATALOG_PRODUCT: '/catalog/delete/{catalogProductId}',
                FAQ: {
                    OVERVIEW: '/catalog/overviewFaq',
                },
                LIST: '/catalog/list',
                GET: '/catalog/get/{catalogProductId}',
                CHECK_PRODUCT_VARIANTS_ATTRIBUTES: '/catalog/checkProductVariantsAttributes/{catalogProductId}',
                GET_AGREEMENTS_FOR_APPLICATION: '/catalog/agreements/getByApplication',
                GET_ATTRIBUTES_FOR_CATALOG_BY_PRESET: '/catalog/attributes/getAvailableAttributes',
                IMAGES: {
                    GET_PREVIEW_URL: '/catalog/images/{catalogProductId}/preview',
                    GET_MARKETPLACE_PREVIEW_URLS: '/catalog/marketplaceProducts/images/preview',
                },
                LIST_CATALOG_PRODUCTS: '/catalog/overview',
                LIST_MARKETPLACE_PRODUCTS: '/catalog/overviewMarketplace',
                SUBMISSION_SPECIFICATIONS: {
                    OVERVIEW: '/catalog/overviewSubmissionSpecifications',
                },
                TAGS: {
                    OVERVIEW: '/catalog/overviewTags',
                },
                UPDATE: '/catalog/update/{catalogProductId}/{section}',
                VARIANTS: {
                    LIST: '/catalog/variants/list',
                    LIST_BY_CATALOG_PRODUCT: '/catalog/variants/{catalogProductId}/list',
                    CLONE: '/catalog/variants/clone/{productVariantId}',
                    CREATE: '/catalog/variants/create',
                    DELETE: '/catalog/variants/delete/{productVariantId}',
                    GET: '/catalog/variants/get/{productVariantId}',
                    PUBLISH: '/catalog/variants/publish/{productVariantId}',
                    UNPUBLISH: '/catalog/variants/unpublish/{productVariantId}',
                    REINDEX: '/catalog/variants/reindex/{productVariantId}',
                    UPDATE: '/catalog/variants/update/{productVariantId}',
                    PRODUCT_SETTINGS: {
                        LIST: '/catalog/variants/{productVariantId}/settings',
                        CREATE: '/catalog/variants/{productVariantId}/settings/create',
                        UPDATE: '/catalog/variants/{productVariantId}/settings/{catalogProductSettingId}/update',
                        REMOVE: '/catalog/variants/{productVariantId}/settings/{catalogProductSettingId}/delete',
                    },
                    OVERVIEW_IDS_AND_NAMES: '/catalog/variants/overviewidsandnames',
                    APPLICATIONS: {
                        PUBLISH: '/catalog/variants/applications/publish',
                        UNPUBLISH: '/catalog/variants/applications/unpublish',
                    },
                },
                GENERATE_PRODUCT_VARIANTS_FILE: '/catalog/generateProductVariantsFile'
            },
            CATEGORIES: {
                OVERVIEW: '/category/overview',
                ADD: '/category/add',
                UPDATE: '/category/update',
                REMOVE: '/category/remove/{categoryId}',
                ADD_PRODUCT: '/category/product/{categoryId}/{catalogId}/add',
                REMOVE_PRODUCT: '/category/product/{categoryId}/{catalogId}/remove',
            },
            COMPLAINTS: {
                OVERVIEW: '/complaints/overview/{agentId}',
                ADD: '/complaints/{accountId}/add',
                UPDATE: '/complaints/{accountId}/{orderId}/update',
                SUBMIT: '/complaints/{accountId}/{orderId}/submit',
                REFUND: '/complaints/{accountId}/{orderId}/refund',
                REPRINT: '/complaints/{accountId}/{orderId}/reprint',
                UPLOAD_BULK: '/complaints/uploadbulk/{orderId}/{complaintId}',
                GET_DOWNLOAD_URL: '/complaints/downloadurl/{orderId}/{complaintId}/{objectName}',
                REMOVE_FILE: '/complaints/removefile/{orderId}/{complaintId}/{objectName}',
                GET_FORM_DATA: '/complaints/formdata/overview',
                ORDERLINE: {
                    OVERVIEW: '/complaints/overview/{orderLineId}',
                },
                TREE: {
                    GET: '/complaints/gettree/{decisionTreeUrl}',
                },
            },
            FAST_EDITOR: {
                OVERVIEW: '/fasteditor/overview',
            },
            CUSTOMERS: {
                OVERVIEW: '/customers/overview/{agentId}',
                ADD: '/customers/add',
                UPDATE: '/customers/update/{customerId}',
                REVOKE: '/customers/revoke/{customerId}',
                REACTIVATE: '/customers/reactivate/{customerId}',
                IMPERSONATE: '/customers/impersonate/{customerId}',
                RESET_PASSWORD: '/customers/password/reset-request',
                GET: {
                    OVERVIEW: '/customers/{customerId}',
                    ADDRESSES: '/customers/{customerId}/{organisationId}/addresses',
                },
                ADDRESS: {
                    ADD: '/customers/{customerId}/{organisationId}/address/add',
                    UPDATE: '/customers/{customerId}/{organisationId}/address/update',
                    REMOVE: '/customers/{customerId}/{organisationId}/address/{addressId}/remove',
                    SET_DEFAULT_BILLING: '/customers/{customerId}/address/{addressId}/setdefaultbillingaddress',
                    SET_DEFAULT_SHIPPING: '/customers/{customerId}/address/{addressId}/setdefaultshippingaddress',
                },
                GROUPS: {
                    OVERVIEW: '/customers/groups/overview',
                    ADD: '/customers/groups/add',
                    GET: '/customers/groups/{groupId}',
                    UPDATE: '/customers/groups/update',
                    REVOKE: '/customers/groups/revoke',
                    LIST_PRICE_GROUPS: '/customers/groups/pricegroups/overview',
                },
                CONFIGURATIONS: {
                    ADD: '/customers/configurations/add',
                    GET_AVAILABLE_SYSTEM_CONFIGURATIONS: '/customers/configurations/available_system_configurations',
                    APPLICATION_ACCOUNT_CONFIGURATION_SCHEMAS: '/customers/configurations/application_account_configuration_schemas',
                    CONFIGURATION_SCHEMAS: '/customers/configurations/configuration_schemas',
                    FILTERED_OVERVIEW: '/customers/configurations/filtered_overview?configurationType={configurationType}',
                    GET: {
                        OVERVIEW: '/customers/configurations/{accountConfigurationId}',
                    },
                    OVERVIEW: '/customers/configurations/overview',
                    DELETE: '/customers/configurations/delete?accountConfigurationId={accountConfigurationId}',
                    UPDATE: '/customers/configurations/update',
                },
                NOTES: {
                    OVERVIEW: '/customers/{customerId}/notes/overview',
                    ADD: '/customers/{customerId}/{agentId}/notes/add',
                    REMOVE: '/customers/{customerId}/{noteId}/notes/remove',
                },
            },
            INVOICES: {
                ACCOUNT: '/invoices/account/{accountId}/{agentId}',
                DOWNLOAD: '/invoices/downloadurl/{objectName}',
                OVERVIEW: '/invoices/overview/{agentId}',
                BULK_OVERVIEW: '/invoices/overview/{agentId}/bulk',
            },
            LOGIN: '/auth/login',
            NOTES: {
                ADD: '/logs/{accountId}/{orderId}/notes/add',
                UPDATE: '/logs/{accountId}/{orderId}/notes/update',
                REMOVE: '/logs/{accountId}/{orderId}/{noteId}/remove',
            },
            ORDERS: {
                OVERVIEW: '/orders/overview/{accountId}',
                DELAYED_OVERVIEW: '/orders/delayedOverview/{agentId}',
                GET: '/orders/{orderId}/{accountId}/get',
                ADD: '/orders/add/{accountId}',
                BILLING: '/orders/{accountId}/{orderId}/billinginfo',
                DELIVERY: '/orders/{orderId}/orderlines/{accountId}/{orderLineId}/deliveryinfo',
                CANCEL: '/orders/cancel/{accountId}/{orderId}',
                GET_CANCEL_REASONS: '/orders/{orderId}/cancellationReasons',
                GET_HISTORY: '/logs/{accountId}/{orderId}/history',
                LIST_PAYMENT_EVENTS: '/logs/{orderId}/listOrderPaymentEvents',
                GET_FOR_ACCOUNT: '/orders/account/{accountId}',
                ARTWORK_QUEUE: '/orders/queue/overview/{accountId}',
                QUEUE_STATUS: '/orders/queue/status/{accountId}',
                ASSIGN: '/orders/{accountId}/{customerId}/{orderId}/assign',
                ASSIGN_FIRST: '/orders/{accountId}/assign',
                UNASSIGN: '/orders/{accountId}/{customerId}/{orderId}/unassign',
                SKIP: '/orders/{accountId}/{customerId}/{orderId}/skip',
                ASSIGNED_ORDERS: '/orders/queue/assigned/{agentId}',
                APPLY_VOUCHER: '/orders/voucher/apply/{customerId}/{orderId}/{voucher}',
                ADD_ORDER_DISCOUNT: '/orders/{customerId}/addorderdiscount',
                SET_DISCOUNTS: '/orders/{orderId}/{customerId}/setDiscounts',
                ADD_PAYMENT: '/orders/{customerId}/payments/add',
                GET_ORDER_PAYMENTS: '/orders/{orderId}/payments/get-payments',
                ARTWORK_STATUS: '/orders/{orderId}/queue/status',
                SEND_PAY_PER_MAIL: '/orders/sendPayByMailLink/{customerId}/{orderId}',
                SEND_ORDER_COMPLETE_NOTIFICATION: '/orders/sendOrderCompleteNotification/{customerId}/{orderId}',
                GET_VARIATION: '/orders/get/variation',
                CALCULATE_TOTALS: '/orders/{customerId}/calculateOrderTotalsForAccount',
                SLIPS: {
                    OVERVIEW: '/orders/slips/{type}/overview/{agentId}',
                },
                FOLLOWUP: {
                    OVERVIEW: '/orders/followup/overview',
                    SUBMIT: '/orders/followup/{accountId}/{customerId}/{orderId}/{orderLineId}/submit',
                },
                ORDERLINE: {
                    CANCEL: '/orders/{orderId}/orderlines/{accountId}/{orderLineId}/cancel',
                    DUPLICATE: '/orders/{orderId}/orderlines/{accountId}/add',
                    UPDATE: '/orders/{orderId}/orderlines/{accountId}/update',
                    SHIPMENT_EVENTS: '/orders/{orderId}/orderlines/{accountId}/{orderLineId}/shipmentevents',
                    NO_PROOF: '/orders/{orderId}/orderlines/{accountId}/{orderLineId}/noproofrequested',
                    SET_STATUS: '/orders/{orderId}/orderlines/{accountId}/{orderLineId}/status',
                },
                DISCOUNTS: {
                    CHECK: '/orders/{orderId}/{customerId}/checkForDiscounts',
                    CONFIRM_CHECK: '/orders/{orderId}/{customerId}/checkForDiscounts/confirm',
                },
            },
            PAYMENT: {
                REFUND_SLIP: '/payments/order/slip/refund/{customerId}',
                REFUND_PAYMENT: '/payments/order/slip/refund-payment/{customerId}',
            },
            PERMISSIONS: {
                OVERVIEW: '/permissions/overview',
                ADD: '/permissions/add',
                GET: '/permissions/{permissionId}',
                UPDATE: '/permissions/update',
                REVOKE: '/permissions/revoke',
            },
            SMART_SPY: {
                OVERVIEW: '/smartspy/overview',
                CREATE_TASK: '/smartspy/create-task',
                RETRY_TASK: '/smartspy/retry-task',
                LIST_HISTORY: '/smartspy/history',
            },
            PRODUCT_FEEDS: {
                OVERVIEW: '/productfeeds/overview',
                CREATE: '/productfeeds/create',
                UPDATE: '/productfeeds/update/{productFeedId}',
                GET: '/productfeeds/get/{productFeedId}',
                DELETE: '/productfeeds/delete/{productFeedId}',
                DETAILS: '/productfeeds/details/{productFeedId}',
                GET_DOWNLOAD_URL: '/productfeeds/download',
                CONFIGURATIONS: {
                    OVERVIEW: '/productfeeds/configurations/overview',
                    CREATE: '/productfeeds/configurations/create',
                    UPDATE: '/productfeeds/configurations/update',
                    REVOKE: '/productfeeds/configurations/revoke/{configurationId}',
                    GET: '/productfeeds/configurations/get/{configurationId}',
                },
                FIELDS: {
                    OVERVIEW: '/productfeeds/fields/overview',
                    CREATE: '/productfeeds/fields/create',
                    UPDATE: '/productfeeds/fields/update',
                    REVOKE: '/productfeeds/fields/revoke/{fieldId}',
                    GET: '/productfeeds/fields/get/{fieldId}',
                },
            },
            PRODUCTS: {
                GET: '/products/content/{slug}',
                ATTRIBUTES: '/products/attributes',
                VARIATION: '/products/variation',
            },
            PRODUCTS_MANAGEMENT: {
                GET_AGREEMENTS: '/productsmanagement/agreements/overview',
                GET_PRODUCT_DEFINITIONS: '/productsmanagement/agreements/definitions/{agreementId}',
                GET_ATTRIBUTES_BY_SHOP_AND_PRODUCT: '/productsmanagement/attributes/product/overview/{productHash}/{shopHash}',
                GET_ATTRIBUTES_BY_SHOP: '/productsmanagement/attributes/overview/{shopHash}',
                GET_ATTRIBUTES_FOR_FUNNEL: '/productsmanagement/attributes/product/funnel/{productHash}/{shopHash}',
                LIST_PRODUCT_DEFINITION_MAPPINGS: '/productsmanagement/agreements/definitions/mappings/{productHash}/{shopHash}',
                LIST_PRODUCT_DEFINITION_MAPPINGS_OVERVIEW: '/productsmanagement/agreements/definitions/mappings/overview',
                LIST_PRODUCT_LABELS_PER_SHOP: '/productsmanagement/agreements/definitions/labels/{shopHash}',
                SET_PRODUCT_MAPPING: '/productsmanagement/agreements/definitions/mappings/{productHash}/{agreementId}/{shopHash}/set',
                SET_ATTRIBUTE_OPTIONS_MAPPING: '/productsmanagement/attributes/definitions/mappings/{agreementId}/{shopHash}/set',
                LIST_ATTRIBUTE_OPTIONS_DEFINITION_MAPPINGS: '/productsmanagement/attributes/definitions/mappings/{agreementId}/{shopHash}/overview',
                REMOVE_PRODUCT_MAPPING: '/productsmanagement/agreements/definitions/mappings/{productHash}/{agreementId}/remove',
                REMOVE_ATTRIBUTE_OPTIONS_MAPPING: '/productsmanagement/attributes/definitions/mappings/{agreementId}/remove',
                LIST_PRODUCTS: '/productsmanagement/products/overview',
                GET_TURNAROUNDS: '/productsmanagement/{shopHash}/{productHash}/turnarounds',
                GET_PRODUCT_TURNAROUNDS: '/productsmanagement/{shopHash}/{productHash}/product-turnarounds',
                LIST_CATALOG_PRODUCT_VARIANTS: '/productsmanagement/products/catalog-variants',
            },
            ROLES: {
                OVERVIEW: '/roles/overview',
                GET: '/roles/{roleId}',
                ADD: '/roles/add',
                UPDATE: '/roles/update',
                REVOKE: '/roles/revoke',
            },
            SEARCH: {
                OVERVIEW: '/search/overview/{agentId}',
            },
            SHOP_CONFIG: {
                OVERVIEW: '/shopconfig/overview/{category}',
                UPDATE: '/shopconfig/update',
                CREATE: '/shopconfig/create',
                REMOVE: '/shopconfig/remove/{configurationId}',
                SYNC: '/shopconfig/sync',
            },
            SUPPLIER: {
                OVERVIEW: '/supplier/overview',
                LIST_BY_SHIP_TO_COUNTRY: '/supplier/listbycountry/{country}',
                GET_TURNAROUND_CALCULATION: '/supplier/getturnaroundcalculation/{turnaroundHash}',
            },
            TURNAROUND_STRATEGY: {
                OVERVIEW: '/turnaroundstrategy/overview',
                GET: '/turnaroundstrategy/get/{strategyId}',
                CREATE: '/turnaroundstrategy/create',
                UPDATE: '/turnaroundstrategy/update/{strategyId}',
                DELETE: '/turnaroundstrategy/delete/{strategyId}',
                GET_MATRIX_FOR_PRODUCT: '/turnaroundstrategy/getByShopHashAndProductHash/{shopHash}/{productHash}',
            },
            VAT_CONFIG: {
                VAT_PROFILES: {
                    LIST: '/vatconfig/vatprofiles/list',
                    REMOVE: '/vatconfig/vatprofiles/{vatProfileId}/remove',
                    UPDATE: '/vatconfig/vatprofiles/update',
                    CREATE: '/vatconfig/vatprofiles/create',
                    LIST_FOR_APPLICATION: '/vatconfig/vatprofiles/{applicationId}/list',
                    FOR_PRODUCT_VARIANT: '/vatconfig/vatprofilesforproductvariant/{productVariantId}',
                },
                COMPANY_PROFILES: {
                    LIST: '/vatconfig/companyprofiles/list',
                    REMOVE: '/vatconfig/companyprofiles/{companyProfileId}/remove',
                    UPDATE: '/vatconfig/companyprofiles/update',
                    CREATE: '/vatconfig/companyprofiles/create',
                },
            },
        },

        REQUEST_DEFAULT: {
            request: {
                startRow: 0,
                endRow: 9999999,
                rowGroupCols: [],
                valueCols: [],
                pivotCols: [],
                pivotMode: false,
                groupKeys: [],
                filterModel: {},
                sortModel: [],
            },
        },
    },

    NAVIGATION: {
        LOGIN_PATH: '/login',
        LOGOUT_PATH: '/logout',
    },

    ORDERS: {
        IMMUTABLE_STATUSES: [
            'READY_FOR_PRODUCTION', 'AWAITING_SUPPLIER', 'IN_PRODUCTION', 'TRANSPORT_FAILED', 'PRODUCED', 'AWAITING_SHIPMENT', 'AWAITING_DELIVERY', 'DELIVERED', 'DELIVERY_FAILED',
        ],
    },

    COOKIES: {
        TOKEN: {
            KEY: 'token',
        },
        LOCALE: {
            KEY: 'locale',
        },
        SESSION: {
            KEY: 'session',
        },
        SHOP: {
            KEY: 'shop',
        },
        ASSIGNED_ORDER: {
            KEY: 'assigned_order',
        },
    },
    SHOP_UNIQUE_VIEWS: ['CustomerView', 'AddOrder', 'AddQuote', 'EditQuote'],
    ARTWORK_GRADES: ['INTERMEDIATE', 'ADVANCED', 'EXPERT', 'MASTER', 'SPECIALIST'],
}
